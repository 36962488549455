.articleheader {
  padding: 0 5vw;
  min-height: 500px;
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 140px;
  text-align: center;
}

.articleheader div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.articleheader div p {
  max-width: 560px;
  margin-bottom: 0;
  margin-top: 120px;
  white-space: pre-wrap;
  max-width: 740px;
  margin-top: 10px;
  margin-bottom: 190px;
  font-size: 1.2rem;
}

.articleheader div span {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.articleheader h1 {
  margin-bottom: 2rem;
  word-break: break-word;
  font-size: 3rem;
}

.articleheader.pageheadermeta div:last-child {
  margin-bottom: 70px;
}

@media only screen and (max-width: 1520px) and (min-width: 1181px) {
  .articleheader div p {
    margin-bottom: 40px;
  }

  .articleheader h1 {
    line-height: 110%;
  }
}

@media only screen and (max-width: 1180px) {
  .articleheader {
    padding-top: 0;
    min-height: 70vh;
    grid-auto-rows: max-content;
  }

  .articleheader div {
  }

  .articleheader div:first-child {
    align-items: flex-start;
  }

  .articleheader h1 {
    margin-top: 180px;
    font-size: 2rem;
    text-align: left;
    word-break: break-word;
    line-height: 160%;
  }

  .articleheader {
    padding: 0 5vw;
    min-height: 340px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  .articleheader div p {
    margin-top: 0;
    text-align: left;
    margin-bottom: 40px;
  }

  .articleheader div span {
    margin-top: 0;
    font-size: 12px;
    margin-bottom: 16px;
    text-align: left;
  }
}

@media only screen and (min-width: 1920px) {
  .articleheader {
    max-width: 1714px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
